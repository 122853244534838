export class CustomError {
  title: string;
  description?: string;
  path?: string;
  key?: string;

  constructor(params: Partial<CustomError>) {
    Object.assign(this, params);
  }
}
